<template>
  <div class='login'>
    <div class="details">
      <div class="title">
        <p><img src="@/assets/icon.png" alt=""></p>
        <h3>{{title}}</h3>
      </div>
      <div class="content">
        <div class="loginImg">
          <img src="@/assets/login.png" alt="">
        </div>
        <div class="loginForm" style="padding: 40px 0 0 40px;">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="登录" name="first">
              <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0" class="demo-ruleForm">
                <el-form-item prop="username">
                  <el-input v-model="loginForm.username" placeholder="请输入用户名">
                    <i slot="prefix"><i class="el-icon-s-custom"></i> |</i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password" v-model="loginForm.password" placeholder="请输入密码">
                    <i slot="prefix"><i class="el-icon-lock"></i> |</i>
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" :loading="loginLoading" @click="submitForm('loginForm')">登录</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="浙政钉登录" name="second" v-if="codeShow">
              <div class="code">
                <iframe width="260" height="300" :style="{border:'none',marginLeft: '-15px',  marginTop: '-80px'}" scrolling="no" :src="codeSrc" @load='onLoad'>

                </iframe>
              </div>

            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

    </div>
    <footer>
      <p>
        <span>平台服务商：杭州钛比科技有限公司</span>
        <span>联系电话： 0571-87755736</span>
      </p>
      <p>
        <span>浙ICP备16003817号-1</span>
        <span>网站标识码：3610782</span>
      </p>
      <p>
        <img src="@/assets/bot.png" alt="">
        <span>浙公网安备33010602009975号</span>
      </p>
    </footer>
  </div>
</template>

<script>
// import { login } from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  data () {

    return {
      activeName: 'first',
      loginForm: {
        password: '',
        username: ''
      },
      rules: {
        password: [
          // { required: true, validator: this.$validator.password, trigger: 'blur' }
        ],
        username: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ]
      },
      loginLoading: false,
      redirect: undefined,
      title: '',
      codeShow: false,
      codeSrc: ''
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['domainName', 'baseUrl', 'codeLogin'])
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.title = this.domainName === '庆元县' ? '庆元卫监监控分析平台' : `${this.domainName}卫健行政执法视频监控智能图像分析平台`
      this.codeShow = this.codeLogin ? true : false
      switch (this.domainName) {
        case '庆元县':
          this.codeSrc = 'https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=qywj_dingoa&redirect_uri=https://qywj.terabits.cn/api/security/codeScanningLogin&scope=get_user_info&authType=QRCODE&embedMode=true'
          break;
        case '温州市':
          this.codeSrc = 'https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=wzwj_dingoa&redirect_uri=https://wzwj.terabits.cn/api/security/codeScanningLogin&scope=get_user_info&authType=QRCODE&embedMode=true'
          break
        case '永嘉县':
          this.codeSrc = 'https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=wzwj_dingoa&redirect_uri=https://wzwj.terabits.cn/api/security/codeScanningLogin&scope=get_user_info&authType=QRCODE&embedMode=true'
          break
      }
    },
    submitForm () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true
          this.$store.dispatch("Login", this.loginForm).then(() => {
            // console.log(this.redirect)
            this.$router.push({ path: this.redirect || "/" }).catch(() => { });
            this.loginLoading = false;
          }).catch(() => {
            this.loginLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    onLoad () {
      const _this = this
      window.addEventListener('message', function (event) {
        var arr = `${_this.baseUrl}/api/security/codeScanningLogin?code=${event.data.code}`
        window.location.href = arr
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;
  background: url("../../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 18;
    margin-top: 120px;
    .title {
      display: flex;
      align-items: center;
      p {
        width: 70px;
        height: 70px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h3 {
        font-size: 36px;
        font-weight: 400;
        color: #325d88;
        margin-left: 20px;
      }
    }
    .content {
      display: flex;
      // align-items: center;
      border-radius: 5px;
      margin-top: 40px;
      box-shadow: -2px 5px 20px #e2e7e7;
      background: #fafcff;
      padding-right: 50px;
      .loginImg {
        width: 460px;
        height: 440px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .loginForm {
        ::v-deep .el-tabs__item {
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        .el-form {
          margin-top: 20px;
          padding-right: 25px;
          .el-form-item {
            margin-bottom: 40px;
            width: 100%;
            .el-input {
              .el-input__prefix {
                i {
                  display: flex;
                  align-items: center;
                }
              }
              ::v-deep .el-icon-s-custom,
              .el-icon-lock {
                color: rgb(50, 93, 136);
                font-size: 18px;
                margin: 0 6px 0 11px;
              }
              ::v-deep .el-input__inner {
                padding-left: 50px;
                font-size: 14px;
              }
            }
            .el-button {
              width: 100%;
              background: rgb(50, 93, 136);
              border-radius: 2px 4px 4px 4px;
              border: none;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  footer {
    margin-top: 80px;
    p {
      // text-align: center;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 20px;
        color: #666;
        font-size: 14px;
      }
      img {
        width: 20px;
        margin-right: -10px;
        // height: 20px;
      }
    }
  }
}
</style>
